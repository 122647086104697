import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: {
    xs: "calc(100vh - 20px)", // for extra-small screens
    sm: "calc(100vh - 40px)", // for small screens
    md: "calc(100vh - 60px)", // for medium screens
    lg: "calc(100vh - 80px)", // for large screens
    xl: "calc(100vh - 100px)", // for extra-large screens
  },
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%", // for extra-small screens
    sm: "80%", // for small screens
    md: "70%", // for medium screens
    lg: "60%", // for large screens
    xl: "50%", // for extra-large screens
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: {
    xs: 2, // for extra-small screens
    sm: 3, // for small screens
    md: 4, // for medium and larger screens
  },
};
const FeedbackModal = forwardRef(({ data, open, handleClose }, ref) => {
  const modalRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getContent: () => modalRef.current
  }));
  function timeConverter(time24) {
    const [hours, minutes] = time24.split(":");
    let suffix = "AM";
    let hour = parseInt(hours, 10);
    if (hour >= 12) {
      suffix = "PM";
      hour = hour === 12 ? 12 : hour - 12;
    }
    if (hour === 0) {
      hour = 12;
    }
    return `${hour}:${minutes} ${suffix}`;
  }
  console.log("--- data --", data);
  function getTrueSubjects(subjects) {
    const trueSubjects = Object.entries(subjects)
      .filter(
        ([subject, value]) => value === true && subject !== "otherSubject"
      )
      .map(([subject]) => subject);

    if (subjects.otherSubject.trim() !== "") {
      trueSubjects.push(subjects.otherSubject);
    }
    return trueSubjects;
  }
  console.log("modall-------", modalRef)
  return (
    <div id="feedback-modal-content" >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} ref={modalRef}>
          <h2 className="text-center mb-3">Feedback data</h2>
          <div className="mb-3">
            <p>
              <strong>UserName:</strong> {data.name}
            </p>
            <p>
              <strong>Program Name:</strong> {data.program}
            </p>
            {data?.formData?.schoolName && (
              <p>
                <strong>School Name:</strong> {data.formData.schoolName}
              </p>
            )}
            {data?.formData?.sessionDate && (
              <p>
                <strong>Session Date:</strong> {data.formData.sessionDate}
              </p>
            )}
            {data?.formData?.sessionStartTime && (
              <p>
                <strong>Session StartTime:</strong>{" "}
                {timeConverter(data.formData.sessionStartTime)}
              </p>
            )}
            {data?.formData?.sessionEndTime && (
              <p>
                <strong>Session EndTime:</strong>{" "}
                {timeConverter(data.formData.sessionEndTime)}
              </p>
            )}
            {data?.formData?.tutorLoginTime && (
              <p>
                <strong>Tutor LoginTime:</strong>{" "}
                {timeConverter(data.formData.tutorLoginTime)}
              </p>
            )}
            {data?.formData?.studentLoginTime && (
              <p>
                <strong>Student LoginTime:</strong>{" "}
                {timeConverter(data.formData.studentLoginTime)}
              </p>
            )}
            {data?.formData?.studentGroupSize && (
              <p>
                <strong>Student Group Size:</strong>{" "}
                {data.formData.studentGroupSize}
              </p>
            )}
            {data?.formData?.sessionType && (
              <p>
                <strong>Session Type:</strong> {data.formData.sessionType}
              </p>
            )}
            {data?.formData?.studentName && (
              <p className="text-capitalize">
                <strong> Student Name:</strong> {data.formData.studentName}
              </p>
            )}
            {data?.formData?.location && (
              <p className="text-capitalize">
                <strong> location:</strong> {data.formData.location}
              </p>
            )}
            {data?.formData?.duration && (
              <p className="text-capitalize">
                <strong>Duration:</strong> {data.formData.duration}
              </p>
            )}
            {data?.formData?.activites && (
              <p className="text-capitalize">
                <strong>Student Actvities:</strong> {data.formData.actvities}
              </p>
            )}
            {data?.formData?.Interaction_Date && (
              <p className="text-capitalize">
                <strong> Interaction Date:</strong>{" "}
                {data.formData.Interaction_Date}
              </p>
            )}
            {data?.formData?.Interaction_Type && (
              <p className="text-capitalize">
                <strong> Interaction Type:</strong>{" "}
                {data.formData.Interaction_Type}
              </p>
            )}
            {data?.formData?.period && (
              <p>
                <strong> Total Period:</strong> {data.formData.period}
              </p>
            )}
            {data?.formData?.blockPeriod && (
              <p>
                <strong> Was this Block Period:</strong>{" "}
                {data.formData.blockPeriod}
              </p>
            )}
            {data?.formData?.comments && (
              <p>
                <strong> Comments on student learning:</strong>{" "}
                {data.formData.comments}
              </p>
            )}
            {data?.formData?.activeStudent && (
              <p>
                <strong>
                  {" "}
                  Student activity during the specified instruction time:
                </strong>{" "}
                {data.formData.activeStudent}
              </p>
            )}
            {data?.formData?.grades && (
              <p>
                <strong> Student Grades:</strong> {data.formData.grades}
              </p>
            )}
            {data?.formData?.presentStudent && (
              <div className="mb-3">
                <p>
                  <strong>Present Student List</strong>
                </p>
                <div className="p-3 bg-light rounded">
                  {data?.formData?.presentStudent
                    ?.split(",")
                    .map((value, index) => (
                      <p className="text-capitalize" key={index}>
                        <strong>Student Name:</strong> {value}
                      </p>
                    ))}
                </div>
              </div>
            )}

            {data?.formData?.absentStudent && (
              <div className="mb-3">
                <p>
                  <strong>Absent Student List</strong>
                </p>
                <div className="p-3 bg-light rounded">
                  {data?.formData?.absentStudent
                    ?.split(",")
                    .map((value, index) => (
                      <p key={index} className="mb-0 text-capitalize">
                        <strong>Student Name:</strong> {value}
                      </p>
                    ))}
                </div>
              </div>
            )}

            {data?.subjects !== 0 &&
              data.program !== "LAUSD" &&
              data.program !== "OCDE" && (
                <div className="mb-3">
                  <p>
                    <strong>Subjects</strong>
                  </p>
                  <div className="p-3 bg-light rounded">
                    {getTrueSubjects(data.subjects).map((val, index) => (
                      <p key={index} className="mb-1">
                        {val}
                      </p>
                    ))}
                  </div>
                </div>
              )}

            {data?.gradeLevel.length !== 0 && (
              <div className="mb-3">
                <p>
                  <strong>Grades</strong>
                </p>
                <div className="p-3 bg-light rounded">
                  {data.gradeLevel.map((val, index) => (
                    <p key={index} className="mb-1">
                      {val}
                    </p>
                  ))}
                </div>
              </div>
            )}
            <div className="mb-3">
              {data?.studentList.length !== 0 && (
                <>
                  <p>
                    <strong>Student List</strong>
                  </p>
                  <div className="bg-light rounded p-3">
                    {data.studentList.map((student, index) => (
                      <div key={index} className="row mb-2">
                        <div className="col-12 col-md-4">
                          <p className="mb-0 text-capitalize">
                            <strong>Student Name:</strong> {student.student}
                          </p>
                        </div>
                        <div className="col-12 col-md-4">
                          <p className="mb-0">
                            <strong>Attendance:</strong> {student.attendance}
                          </p>
                        </div>
                      </div>
                    ))}
                    <div>
                      {data?.formData?.additionalStudent
                        ?.split(",")
                        .map((value, index) => (
                          <div key={index} className="row mb-2">
                            <div className="col-12">
                              <p className="mb-0 text-capitalize">
                                <strong>Student Name:</strong> {value}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              )}
            </div>

            {data?.formData?.contentFileName && (
              <p className="text-capitalize">
                <strong>Content FileName:</strong>{" "}
                {data.formData.contentFileName}
              </p>
            )}
            {data?.formData?.disposition && (
              <p className="text-capitalize">
                <strong>Disposition:</strong> {data.formData.disposition}
              </p>
            )}
            {data?.formData?.nextSessionConfirmed && (
              <p className="text-capitalize">
                <strong>Next Session Confirmed:</strong>{" "}
                {data.formData.nextSessionConfirmed}
              </p>
            )}
            {data?.formData?.nextSessionConfirmed === "yes" &&
              data.formData.nextSessionDate && (
                <p>
                  <strong>Next Session Date:</strong>{" "}
                  {data.formData.nextSessionDate}
                </p>
              )}
          </div>
        </Box>
      </Modal>
    </div>
  );
});

export default FeedbackModal;
