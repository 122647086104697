import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  TextField,
} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { WascoData, FusdSubjectData, SocialData } from "../formData";
import axios from "axios";
import { BACKEND_URI } from "../../../config/config";
export default function WascoForm({ program }) {
  const [formData, setFormData] = useState(WascoData);
  const [subject, setSubjects] = useState(FusdSubjectData);
  const [social, setSocial] = useState(SocialData);

  const allSubjects = Object.keys(subject).slice(0, -1);
  const allSocial = Object.keys(social).slice(0, -1);

  console.log("selected Program", program);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value, // handle checkboxes and other inputs
    }));
  };
  let userData = JSON.parse(localStorage.getItem("studentNest"));
  const { firstName, lastName, address, email, mobileNumber } = userData;
  const token = localStorage.getItem("jwtToken");
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const handleSubjectChange = (event) => {
    const { name, value, checked, type } = event.target;
    setSubjects((prevSubjects) => ({
      ...prevSubjects,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSocialChange = (event) => {
    const { name, value, checked, type } = event.target;
    setSocial((prevSubjects) => ({
      ...prevSubjects,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      program,
      name: `${firstName} ${lastName}`,
      email,
      contact: mobileNumber,
      address,
      formData,
    };
    if (formData.Interaction_Type === "Academic Support")
      body.subjects = subject;
    else body.subjects = social;

    console.log("=----bodu===", body);
    const response = await axios.post(`${BACKEND_URI}/feedback`, body, {
      headers,
    });
    if (response) {
      toast.success("Form Submitted Succesfully");
      setFormData(WascoData);
      setSubjects(FusdSubjectData);
    }
  };
  return (
    <div className="container my-4">
      <h1 className="text-center mb-3">Tutoring Session Form</h1>
      <form
        onSubmit={handleSubmit}
        className="mw-50 mx-auto text-start"
        style={{ maxWidth: "550px" }}
      >
        <div className="form-group mb-3">
          <label className="d-block fw-bold mb-2">Student Name</label>
          <TextField
            label="Student Name"
            name="studentName"
            size="small"
            value={formData.studentName}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group mb-3">
          <label className="fw-bold mb-2">Location of interaction</label>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel className="block">Location</InputLabel>
            <Select
              size="small"
              name="location"
              placeholder=""
              value={formData.location}
              input={<OutlinedInput label="Location" />}
              onChange={handleInputChange}
              className="form-control"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              required
            >
              <MenuItem value="ATS Room">ATS Room</MenuItem>
              <MenuItem value="Library">Library</MenuItem>
              <MenuItem value="Other Classroom">Other Classroom</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-1">Interaction Date:</label>
          <input
            type="date"
            name="Interaction_Date"
            value={formData.Interaction_Date}
            onChange={handleInputChange}
            className="form-control"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-2">Type Of Interaction</label>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel className="block">Interaction Type</InputLabel>
            <Select
              size="small"
              name="Interaction_Type"
              placeholder="Interaction Type"
              value={formData.Interaction_Type}
              input={<OutlinedInput label="Interaction Type" />}
              onChange={handleInputChange}
              className="form-control"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              required
            >
              <MenuItem value="Academic Support">Academic Support</MenuItem>
              <MenuItem value="Social/Emotional Support">
                Social/Emotional Support
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="form-group mb-3">
          <label className="fw-bold mb-2">
            How long was the academic interaction with the student?
          </label>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel className="block">Duration</InputLabel>
            <Select
              size="small"
              name="duration"
              placeholder="Duration"
              value={formData.duration}
              input={<OutlinedInput label="duration" />}
              onChange={handleInputChange}
              className="form-control"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              required
            >
              <MenuItem value="Less Than 5 minutes">
                Less Than 5 minutes
              </MenuItem>
              <MenuItem value="10-20 minutes">10-20 minutes</MenuItem>
              <MenuItem value="20-30 minutes">20-30 minutes</MenuItem>
              <MenuItem value="30-45 minutes">30-45 minutes</MenuItem>
              <MenuItem value="45-60 minutes">45-60 minutes</MenuItem>
              <MenuItem value="Over 1 Hour">Over 1 Hour</MenuItem>
            </Select>
          </FormControl>
        </div>
        {formData.Interaction_Type === "Academic Support" && (
          <>
            <div className="form-group mb-3">
              <label className="fw-bold mb-1">subject Area Of Support</label>

              <FormGroup>
                {allSubjects.map((subject, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        name={subject}
                        checked={subject[subject]}
                        onChange={handleSubjectChange}
                        className="form-check"
                      />
                    }
                    label={subject}
                  />
                ))}
              </FormGroup>
              <TextField
                label="Other Subject"
                name="otherSubject"
                size="small"
                value={subject.otherSubject}
                onChange={handleSubjectChange}
                className="form-control"
              />
            </div>
            <div className="form-group mb-3">
              <label className="fw-bold mb-2">
                Briefly describe the activities or content you supported the
                student with. 1-2 brief sentences or phrases is sufficient.
              </label>

              <TextField
                label="Activities"
                name="actvities"
                size="small"
                value={formData.actvities}
                onChange={handleInputChange}
                className="form-control"
                required
              />
            </div>
          </>
        )}
        {formData.Interaction_Type === "Social/Emotional Support" && (
          <>
            <div className="form-group mb-3">
              <label className="fw-bold mb-1">
                Area Of Social/Emotional Support
              </label>

              <FormGroup>
                {allSocial.map((subject, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        name={subject}
                        checked={social[subject]}
                        onChange={handleSocialChange}
                        className="form-check"
                      />
                    }
                    label={subject}
                  />
                ))}
              </FormGroup>
              <TextField
                label="Other Subject"
                name="otherSubject"
                size="small"
                value={social.otherSubject}
                onChange={handleSocialChange}
                className="form-control"
              />
            </div>
          </>
        )}
        <div className="text-center my-3">
          <Button
            size="medium"
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
}
