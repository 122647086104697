import { duration } from "@mui/material";

export const FormsData = {
  schoolName: "",
  sessionDate: "",
  sessionStartTime: "",
  sessionEndTime: "",
  tutorLoginTime: "",
  studentLoggedIn: "",
  studentLoginTime: "",
  studentGroupSize: "",
  sessionType: "",
  contentFileName: "",
  disposition: "",
  teacherName: "",
  studentName: "",
  presentStudent: "",
  absentStudent: "",
  nextSessionConfirmed: "",
  nextSessionDate: "",
  additionalStudent: "",
  period: "",
  blockPeriod: "",
  activeStudent: "",
  comments: "",
  grades: "",
};
export const WascoData = {
  studentName: "",
  location: "",
  Interaction_Date: "",
  Interaction_Type: "",
  duration: "",
  actvities: "",
};
export const ProgramsData = [
  { value: "", label: "Choose" },
  { value: "CMS", label: "CMS (Charlotte-Mecklenburg)" },
  { value: "CVUSD", label: "CVUSD (Chino Valley)" },
  { value: "FUSD_Edge", label: "FUSD Edge (Fresno Edge)" },
  { value: "KCOE", label: "KCOE (Kings County)" },
  { value: "LACDA", label: "LACDA" },
  { value: "LACOE_GAIN", label: "LACOE GAIN (L.A. County - Gain)" },
  { value: "LAUSD", label: "LAUSD" },
  { value: "Louisiana_REAL", label: "Louisiana (REAL)" },
  {
    value: "Louisiana_SteveCarter",
    label: "Louisiana (Steve Carter Literacy)",
  },
  { value: "OCDE", label: "OCDE (Orange County)" },
  { value: "Palm_Beach", label: "Palm Beach (Florida)" },
  { value: "San_Mateo", label: "San Mateo (California)" },
  { value: "SB_DCFS", label: "SB DCFS (San Bernardino)" },
  { value: "TWC", label: "TWC (Texas Workforce Commission)" },
  { value: "Wasco", label: "Wasco (California)" },
  { value: "Other", label: "Other (Not Listed)" },
];
export const classPeriod = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7-11", label: "7-11" },
];

export const GradeData = {
  "Pre-K, TK, or 4K": false,
  Kinder: false,
  "1st Grade": false,
  "2nd Grade": false,
  "3rd Grade": false,
  "4th Grade": false,
  "5th Grade": false,
  "6th Grade": false,
  "7th Grade": false,
  "8th Grade": false,
  "9th Grade": false,
  "10th Grade": false,
  "11th Grade": false,
  "12th Grade": false,
  "College+": false,
};
export const GradeArray = [
  { value: "Pre-K, TK, or 4K" },
  { value: "Kinder" },
  { value: "1st Grade" },
  { value: "2nd Grade" },
  { value: "3rd Grade" },
  { value: "4th Grade" },
  { value: "5th Grade" },
  { value: "6th Grade" },
  { value: "7th Grade" },
  { value: "8th Grade" },
  { value: "9th Grade" },
  { value: "10th Grade" },
  { value: "11th Grade" },
  { value: "12th Grade" },
  { value: "College+" },
];

export const SubjectData = {
  Math: false,
  "ELA/Writing": false,
  "ELA/Reading": false,
  Science: false,
  "Social Studies/History": false,
  otherSubject: "",
};
export const FusdSubjectData = {
  Math: false,
  "ELA/Writing": false,
  "ELA/Reading": false,
  Science: false,
  "History/ Social Science (includes Psychology)": false,
  "Foreign Language": false,
  Art: false,
  "Elective course (study skills, digital citizenship, Online learning, etc.": false,
  otherSubject: "",
};
export const SocialData = {
  "Self-awareness": false,
  "Self-management": false,
  "Social awareness": false,
  "Relationship skills": false,
  "History/ Social Science (includes Psychology)": false,
  "Responsible decision making": false,
  otherSubject: "",
};
export const StudentData = [
  { name: "First Student", attendance: "", student: "" },
  { name: "Second Student", attendance: "", student: "" },
  { name: "Third Student", attendance: "", student: "" },
];
