import React, { useState, useRef, useEffect } from "react";
import { BACKEND_URI } from "../../config/config";
import axios from "axios";
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import DataTable, { createTheme } from "react-data-table-component";
import FeedbackModal from "./feedbackModal";
import { ProgramsData } from "./formData";
import { ProgramForm } from "./programForms";

createTheme("solarized", {
    background: {
        default: "#c0dfdf",
    },
    text: {
        primary: "#08272a",
        secondary: "#08272a",
    },
});
export default function Feedback() {
    const [feedbackData, setFeedbackData] = useState([]);
    const [selectedPrograms, setSelectedPrograms] = useState("");
    const [selectedFeedback, setSelectedFeedback] = useState();
    const [showModal, setShowModal] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const userRole = JSON.parse(localStorage.getItem("studentNest")).role;
    const modalRef = useRef(null);

    async function allPrograms() {
        setTableLoading(true);
        // const res = await axios.get(`${BACKEND_URI}/active_programs`)
        const feedback = await axios.get(`${BACKEND_URI}/feedback`);
        setTableLoading(false);
        if (feedback) setFeedbackData(feedback?.data);
        else {
            console.log("no record found");
        }
    }
    useEffect(() => {
        allPrograms();
    }, []);
    const columns = [
        {
            name: (
                <span style={{ fontSize: "15px", fontWeight: "600" }}>Full Name</span>
            ),
            selector: (row) => row.name,
            sortable: true,
            grow: 2,
        },
        {
            name: <span style={{ fontSize: "15px", fontWeight: "600" }}>Email</span>,
            selector: (row) => row.email,
            sortable: true,
            grow: 2,
        },

        {
            name: <span style={{ fontSize: "15px", fontWeight: "600" }}>Mobile</span>,
            selector: (row) => row.contact,
            sortable: true,
        },
        {
            name: (
                <span style={{ fontSize: "15px", fontWeight: "600" }}>Location</span>
            ),
            selector: (row) => row.address,
            sortable: true,
            grow: 1,
        },
        {
            name: (
                <span style={{ fontSize: "15px", fontWeight: "600" }}>Control</span>
            ),
            cell: (row) => (
                <>
                    <button
                        className="btn btn-xs btn-infoss me-2 mt-1"
                        // style={{ paddig: "0" }}
                        title="View"
                    >
                        <i
                            className="fa-solid fa-eye"
                            style={{ color: "white" }}
                            onClick={() => handleFeedback(row)}
                        ></i>
                    </button>
                    {/* <button
                        className="btn btn-xs me-2 mt-1"
                        style={{ backgroundColor: '#8B0000' }}
                        title="Download PDF"
                    >
                        <i
                            className="fa-solid fa-download"
                            style={{ color: "white" }}
                            onClick={() => handleDownload(row)}
                        ></i>
                    </button> */}

                </>
            ),
        },
    ];
    const MenuProps = {
        PaperProps: {
            style: { maxHeight: 300 },
        },
    };
    const handleFeedback = (data) => {
        setSelectedFeedback(data);
        setShowModal(true);
    };
   
    const handleChangePrograms = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedPrograms(value);
    };
    return (
        <>
            <div className="container">
                <div className="row user-box-1">
                    <div className="col-lg-12 col-12  d-flex justify-content-center  justify-content-between align-items-center pt-3 pb-3">
                        <h4 className="user-h4 mt-2">FEEDBACK</h4>
                    </div>
                </div>
                <div
                    className="row d-flex justify-content-center justify-content-between pt-3 pb-3 align-items-center ps-md-3 pe-md-3"
                    style={{ background: "#c7d7df" }}
                >
                    <div className="col-lg-7  ">
                        <div className="row  d-flex justify-content-lg-end mt-2">
                            <div className="col-lg-6 mt-2">
                                {userRole.includes("Teacher") && (
                                    <FormControl className="select-width" size="small">
                                        <InputLabel id="demo-multiple-name-label">
                                            Select Programs
                                        </InputLabel>
                                        <Select
                                            labelId="demo-multiple-name-label"
                                            id="demo-multiple-name"
                                            placeholder="select program"
                                            input={<OutlinedInput label="Select Programs" />}
                                            label="program"
                                            className="text-start"
                                            sx={{ height: "50px" }}
                                            MenuProps={MenuProps}
                                            value={selectedPrograms}
                                            onChange={handleChangePrograms}
                                        >
                                            {ProgramsData.map((val, index) => (
                                                <MenuItem key={index} value={val.value}>
                                                    {val.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                        </div>
                    </div>
                    {selectedPrograms && (
                        <ProgramForm selectedPrograms={selectedPrograms} />
                    )}
                    {(userRole.includes("Admin") ||
                        userRole.includes("Manager") ||
                        userRole.includes("Employee")) && (
                            <>
                                {tableLoading ? (
                                    <div
                                        className="d-flex justify-content-center align-items-center mt-4 "
                                        style={{ width: "75%", position: "fixed", zIndex: "20000" }}
                                    >
                                        <div className="spinner"></div>
                                    </div>
                                ) : (
                                    <DataTable
                                        columns={columns}
                                        data={feedbackData}
                                        pagination
                                        fixedHeader
                                        fixedHeaderScrollHeight="600px"
                                        highlightOnHover
                                        subHeader
                                        theme="solarized"
                                        striped
                                        // subHeaderComponent={
                                        //     <input type="text" placeholder='Search User' className='form-control' style={{ width: '28%' }} value={searchInactive} onChange={(e) => setSearchInactive(e.target.value)} />
                                        // }
                                        responsive={true}
                                    />
                                )}
                            </>
                        )}
                </div>
            </div>
            {showModal && (
                <FeedbackModal
                    ref={modalRef}
                    data={selectedFeedback}
                    open={showModal}
                    handleClose={() => setShowModal(false)}
                />
            )}
        </>
    );
}
