import CmsForm from "./feedbackForms/cmsForm";
import ChinoValleyForm from "./feedbackForms/chinoValleyForm";
import FresnoForm from "./feedbackForms/fusdForm";
import LacdaForm from "./feedbackForms/lacdaForm";
import LacoeGainForm from "./feedbackForms/lacoeForm";
import LausdForm from "./feedbackForms/lausdForm";
import LouisianaCarterForm from "./feedbackForms/louisianaCarter";
import OcdeForm from "./feedbackForms/ocdeForm";
import WascoForm from "./feedbackForms/wascoForm";
export const ProgramForm = ({ selectedPrograms }) => {
  switch (selectedPrograms) {
    case "CMS":
      return <CmsForm program={selectedPrograms} />;
    case "CVUSD":
    case "KCOE":
      return <ChinoValleyForm program={selectedPrograms} />;
    case "FUSD_Edge":
      return <FresnoForm program={selectedPrograms} />;
    case "LACDA":
      return <LacdaForm program={selectedPrograms} />;
    case "LACOE_GAIN":
    case "Louisiana_REAL":
      return <LacoeGainForm program={selectedPrograms} />;
    case "LAUSD":
      return <LausdForm program={selectedPrograms} />;
    case "Louisiana_SteveCarter":
    case "Palm_Beach":
    case "San_Mateo":
    case "SB_DCFS":
    case "TWC":
      return <LouisianaCarterForm program={selectedPrograms} />;
    case "OCDE":
      return <OcdeForm program={selectedPrograms} />;
    case "Wasco":
      return <WascoForm program={selectedPrograms} />;
    default:
      return;
  }
};
